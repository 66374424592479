
<template>
    <div class="row gy-2">
      <div class="col-12">
        <h5 class="mb-3">Audit Trails</h5>
        <div class="row mb-3 gy-2 justify-content-between">
          <div class="col-md-6 col-lg-4 d-flex align-items-center">
            <div class="input-group w-auto">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              <button @click.prevent="toggleFilters()" type="button" class="btn btn-primary" >
                <i class="ri ri-filter-2-line align-bottom"></i>  Filters </button>
            </div>
          </div>
          <div class="col-md-5">
            <div class="input-group mb-0">
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <common-portlet headertitle="Filters"
            :visible="enableFilters" :open="true">
            <div class="loading-viewport">
              <div class="card-body p-lg-3">
                <audit-trail-filter-form 
                @startSearch="setFilters($event)"
                @resetFilters="initializeAll()" />
              </div>
            </div>
          </common-portlet>
        </div>
        <div class="mb-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <div class="card">
          <div class="card-body p-3">
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                  v-slot:[`head(${column.key})`] >
                    <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                    d-flex align-items-center">
                      {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                    </div>
                </template>
                <template v-slot:cell(user)="data">
                  <div  v-if="data.item.user"  style="min-width:10rem;" class="cursor-pointer d-flex align-items-center">
                    <img class="avatar rounded-circle me-2" :src="absoluteUrl(data.item.user.image)" />
                    <span class="text-dark font-sm text-capitalize">{{ data.item.user.first_name }} {{ data.item.user.last_name}}</span>
                  </div>
                </template>
                <template v-slot:cell(title)="data">
                  <div style="min-width:10rem;" class="cursor-pointer">
                    {{ data.item.title }}
                  </div>
                </template>
                <template v-slot:cell(description)="data">
                  <div style="min-width:20rem;" class="cursor-pointer">
                    <span class="text-dark font-sm">{{ data.item.description }}</span>
                  </div>
                </template>
                <template v-slot:cell(created_at)="data">
                  <div style="width:7rem;" class="cursor-pointer">
                    {{$filters.date(data.item.created_at) }} 
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  
  import _ from 'lodash';
  import CommonPortlet from '@/components/portlets/CommonPortlet.vue';
  import IsLoading from '@/components/IsLoading.vue';
  import AuditTrailFilterForm from '@/components/forms/AuditTrailFilterForm.vue';
  
  export default {
    name: "case-list",
    components:{
      IsLoading,
      CommonPortlet,
      AuditTrailFilterForm
    },
    data() {
      return {
        isLoading: false,
        enableFilters: false,
        queryFilters: null,
        searchQuery:"",
        pageOptions: [10, 50, 100, 500, 1000],
        columns: [
          {
              key: "user",
              label: "User",
          },
          {
              key: "title",
              label: "Event",
              sortable: true
          },
          {
              key: "description",
              label: "Description",
          },
          {
              key: "created_at",
              label: "Created at",
              sortable: true
          },
        ],
        sortBy: {
          sort_column: "created_at",
          sort_direction: "desc"
        },
        pageData:{
          data: []
        }
      }
    },
    computed: {
      currentPage: {
        get() {
          return this.pageData.current_page
        },
        set(val) {
          if(!this.pageData.current_page || this.pageData.current_page == val) return
          this.fetchItems(val)
        }
      },
      itemsPerPage: {
        get() {
          return +this.pageData.per_page || 10
        },
        set(val) {
          this.fetchItems(1, val)
        }
      },
      paginationInfo(){
        if(!this.pageData.total) return '0 - 0 of 0'
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
      },
      getSortingLabel(){
        return key => {
          let iconClass = 'text-muted ri-arrow-up-line';
          if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
          iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
          return `<span class='ms-2 ${iconClass}'></span>`
        }
      }
    },
    methods: {
      updateSearchQuery: _.debounce(function(string) {
        this.fetchItems()
      }, 2000),
      fetchItems(page, per_page = null){
        let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
          ...this.sortBy
        }
        if(this.defaultStatus){ payload.status = this.defaultStatus }
        if(this.searchQuery){ payload.search = this.searchQuery }
        if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
        this.isLoading = true
        this.$http.get(`/audit-trails?${new URLSearchParams(payload).toString()}`)
            .then((response) => {
              this.isLoading = false
              if(response.data.success){
                this.pageData = response.data.data
              }
            })
      },
      toggleFilters(){
        this.enableFilters = !this.enableFilters; 
        this.initializeAll()
      },
      setFilters(data){
        this.queryFilters = data
        this.fetchItems()
      },
      setSortOrder(column) {
        if (this.sortBy.sort_column === column.key) {
          this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
        } else {
          this.sortBy.sort_column = column.key;
          this.sortBy.sort_direction = 'desc';
        }
        this.fetchItems();
      },
      exportCases() {
        const payload = {
          url: '/cases/export-excel',
          filename: 'cases_'
        }
        this.$store.dispatch("changeLoaderValue", true)
        this.$store.dispatch('serverExport', payload)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.status != 200){
              this.alertError('Could not generate cases');
            }
        })
      },
      initializeAll(){
        this.queryFilters = null
      }
    },
    mounted() {
      this.fetchItems()
    },
  }
  </script>
  
  